import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../Store/store";
import { ANDY_API } from "../api/requests";

import { GetStats } from "../types";

export interface HomeSliceState {
  userQueries: number;
  allQueries: number;
  isChatBotModalOpened: boolean;
  messageForUploadedFile: string;
}

const initialState: HomeSliceState = {
  userQueries: 0,
  allQueries: 0,
  isChatBotModalOpened: false,
  messageForUploadedFile: "",
};

export const fetchStats = createAsyncThunk<GetStats>(
  "stats/fetch",
  async () => {
    return await ANDY_API.getStats();
  },
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setIsChatBotModalOpened: (state, action: PayloadAction<boolean>) => {
      state.isChatBotModalOpened = action.payload;
    },
    setMessageForUploadedFile: (state, action: PayloadAction<string>) => {
      state.messageForUploadedFile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.userQueries = action.payload.user_queries;
      state.allQueries = action.payload.all_users_queries;
    });
  },
});

export const selectQueryStats = (state: RootState) => ({
  userQueries: state.home.userQueries,
  allQueries: state.home.allQueries,
});

export const { setIsChatBotModalOpened, setMessageForUploadedFile } =
  homeSlice.actions;
export default homeSlice.reducer;
