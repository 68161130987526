import Pusher from "pusher-js";
import { FC, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import CryptoHandoff from "./Pages/CryptoHandoff/CryptoHandoff";
import NotFound from "./Pages/NotFound/NotFound";
import { routeComponents } from "./Routes/routes";
import {
  getGameResults,
  removeMember,
  replaceCurrentGame,
  setCurrentQuestion,
  setInvitedGameWithModal,
  setNewMember,
} from "./Slice/gameSlice";
import { fetchUserInfo, fetchUserNotificationsNew } from "./Slice/userSlice";
import { PUSHER_ENV } from "./constants";
import History from "./helpers/History";
import runOneSignal, {
  getNotificationPermission,
  setPlayerId,
} from "./helpers/oneSignal";

const App: FC = () => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.user.info?.user_id);

  useEffect(() => {
    if (userId) {
      runOneSignal();
      setPlayerId(userId);
      getNotificationPermission();

      const newPusher = new Pusher(PUSHER_ENV, {
        cluster: "eu",
      });

      const channelName = `${userId}_channel`;
      const newChannel = newPusher.subscribe(channelName);

      newChannel.bind("refresh_profile", () => {
        dispatch(fetchUserInfo({}));
      });

      newChannel.bind("notififcation_list_refresh", () => {
        dispatch(fetchUserNotificationsNew());
      });

      const gameChannelName = `${userId}_game_channel`;
      const newGameChannel = newPusher.subscribe(gameChannelName);

      newGameChannel.bind("new_member", (data) => {
        dispatch(setNewMember(data));
      });

      newGameChannel.bind("exit_member", (data) => {
        dispatch(removeMember(data));
      });

      newGameChannel.bind("game_ready", (data) => {
        dispatch(replaceCurrentGame(data.game));
      });

      newGameChannel.bind("game_started", (data) => {
        if (userId !== data.game.userId) {
          dispatch(setInvitedGameWithModal(data.game));
        }
      });

      newGameChannel.bind("game_question", (data) => {
        dispatch(setCurrentQuestion(data));
      });

      newGameChannel.bind("game_completed", (data) => {
        dispatch(getGameResults(data));
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const script = document.createElement('script');
      script.src = "https://veraviews.com/scripts/c5.js?srid=1401";
      script.async = true;
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [userId]);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <History />
        <Routes>
          {...routeComponents}
          <Route path="/crypto-handoff" element={<CryptoHandoff />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
