import { MULTIMODAL_HISTORY_LIMIT } from "../constants";
import { handleApiError } from "../helpers/string";
import api from "./api";

import {
  DocumentType,
  ChatHistoryMessageType,
  FileType,
  FetchSessionResponse,
  ChatHistoryItemType,
  MediaType,
} from "../types";

interface PostCreateDocumentParams {
  url: string;
  name?: string;
  size_bytes?: number;
  storage_id: "default";
  session_id: string;
}

interface PostCreateAudioParams {
  media_url: string;
  name?: string;
  size_bytes?: number;
  storage_id: "default";
  session_id: string;
  is_recorder?: boolean;
}

interface PostCreateImageParams {
  url: string;
  session_id: string;
}

interface PostTextMessageParams {
  query: string;
  sessionId: string;
}

interface GetChatMessageByIdParams {
  sessionId: string;
  messageId: string;
}

interface PostBase64Response {
  success: boolean;
  url: string;
  metadata: {
    success: boolean;
    size: number;
    url: string;
    metadata: FileType;
  };
}

export const MULTIMODAL_API = {
  async postCreateSession(return_greeting: boolean = true) {
    try {
      const { data } = await api.post<FetchSessionResponse>(
        `/andy/api/chat/session`,
        {
          return_greeting,
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postCreateDocument(body: PostCreateDocumentParams) {
    try {
      const { data } = await api.post<{ document: DocumentType }>(
        `/media/api/v2/account/document`,
        body,
      );
      return data.document;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postCreateAudio(body: PostCreateAudioParams) {
    try {
      const { data } = await api.post<MediaType>(
        `/media/api/v2/account/audio`,
        body,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postCreateImage(body: PostCreateImageParams) {
    try {
      const { data } = await api.post(`/media/api/v2/account/image`, body);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postTextMessage({ sessionId, query }: PostTextMessageParams) {
    try {
      const { data } = await api.post(`/andy/api/chat/v2/${sessionId}/query`, {
        query,
        // mode: "default",
        // model: undefined,
        actions_enabled: true,
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getChatMessageById({ sessionId, messageId }: GetChatMessageByIdParams) {
    try {
      const { data } = await api.get<{
        chat_message: ChatHistoryMessageType;
      }>(`/andy/api/chat/${sessionId}/message/${messageId}`);
      return data?.chat_message;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getChatSessions(key?: string) {
    try {
      const { data } = await api.get<{
        sessions: ChatHistoryItemType[];
      }>(`/andy/api/chat/sessions`, {
        params: {
          key,
          limit: MULTIMODAL_HISTORY_LIMIT,
        },
      });
      return data.sessions;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getChatHistoryById(session_id: string, key?: string) {
    try {
      const { data } = await api.get<{
        messages: ChatHistoryMessageType[];
      }>(`/andy/api/chat/${session_id}/messages`, {
        params: {
          key,
          limit: MULTIMODAL_HISTORY_LIMIT,
        },
      });
      return data.messages;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getStats() {
    try {
      const { data } = await api.get(
        `/company/api/account/proxy/user_service/query/stats`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postCreateBse64Image(body: { base64string: string }) {
    try {
      const { data } = await api.post<PostBase64Response>(
        `/media/api/account/image/upload/base64`,
        body,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postWebPagePdfExtractor(link: string) {
    const { data } = await api.post<{ url: string; title: string }>(
      `/media/api/account/webpage/extractor`,
      {
        link,
      },
    );
    return data;
  },

  async putIngestDocument(id: string, session_id: string) {
    try {
      const { data } = await api.put(
        `/media/api/v2/account/document/${id}/ingest`,
        {
          session_id,
        },
      );

      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async putIngestAudio(id: string, session_id: string) {
    try {
      const { data } = await api.put(
        `/media/api/v2/account/audio/${id}/ingest`,
        {
          session_id,
        },
      );

      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async putIngestImage(id: string, session_id: string) {
    try {
      const { data } = await api.put(
        `/media/api/v2/account/image/${id}/ingest`,
        {
          session_id,
        },
      );

      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};
